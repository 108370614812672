var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"suggestionsTable"},[_c('colel-card',{attrs:{"title":(!_vm.isRecommendations ? _vm.$t('suggestionsTable.title'): _vm.$t('suggestionsTable.titleRecommendations')),"isLoading":_vm.isLoading,"icon":'mdi-account-supervisor-circle'},scopedSlots:_vm._u([{key:"extra-header",fn:function(){return [(!_vm.isRecommendations)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.addTest}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,1172860117)},[_c('span',[_vm._v(_vm._s(_vm.$t("testTable.add")))])]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('colel-table',{attrs:{"value":_vm.dataSorted,"tableId":'suggestions-data-state',"filters":_vm.filters,"loading":_vm.isLoading,"loadingTitle":_vm.$t("testTable.loading"),"rowClass":_vm.rowClass,"expandedRows":_vm.expandedRows,"rows":_vm.dataActiveLength,"sortField":"status_type"},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"tableExpansion",fn:function(slotProps){return [(slotProps.slotProps.entity_type
			  === 1)?_c('div',{staticClass:"p-grid p-jc-center",staticStyle:{"background":"#e7eced8a"}},[_c('colelDetails',{ref:"details",staticClass:"p-col-12 p-md-6 p-lg-5",attrs:{"colel":slotProps.slotProps.new_entity,"changesObj":slotProps.slotProps.changes},on:{"updateColelSuggestion":function($event){return _vm.updateColelSuggestion($event, slotProps.slotProps)}}}),_c('colelHours',{staticClass:"p-col-12 p-md-6 p-lg-5",attrs:{"introShow":false,"colelProp":slotProps.slotProps.new_entity,"changes":slotProps.slotProps.changes.hours},on:{"updateColelSuggestion":function($event){return _vm.updateHoursSuggestion($event, slotProps.slotProps)}}})],1):_vm._e(),(slotProps.slotProps.entity_type === 2)?_c('div',{staticStyle:{"background":"#e7eced8a"}},[_c('personDetails',{attrs:{"person":slotProps.slotProps.new_entity,"changes":slotProps.slotProps.changes},on:{"updatePersonSuggestion":function($event){return _vm.updatePersonSuggestion($event, slotProps.slotProps)}}})],1):_vm._e()]}},{key:"tableContent",fn:function(){return [(_vm.isRecommendations)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px; text-align: center","sortable":"","sortField":"suggestion_type"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('i',{class:_vm.typeIcon(slotProps.data.suggestion_type)})]}}],null,false,3568323978)}):_vm._e(),_c('Column',{key:"status_type",attrs:{"filterMatchMode":"contains","filterField":"status_type","field":"status_type","header":_vm.$t("suggestionsTable.status_type"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible","sortable":"","sortField":"status_type"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{class:{
  'status-accept':
slotProps.data.status_type === 3,
'status-reject':
slotProps.data.status_type === 4,
'status-suspend':
slotProps.data.status_type === 2,
'status-unread':
slotProps.data.status_type === 1}},[_vm._v(" "+_vm._s(_vm.getStatus(slotProps.data.status_type))+" ")])]}}])}),_c('Column',{key:"colel_id",attrs:{"field":"colel_id","header":_vm.$t("suggestionsTable.colel_id"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible","sortable":"","sortField":"colel_id","filterMatchMode":"contains"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('suggestionsTable.filterBy', {
                      name: _vm.$t("suggestionsTable.colel_id"),
                    })},model:{value:(_vm.filters['colel_id']),callback:function ($$v) {_vm.$set(_vm.filters, 'colel_id', $$v)},expression:"filters['colel_id']"}})]},proxy:true}])}),_c('Column',{key:"entity_type",attrs:{"field":"entity_type","header":_vm.$t("suggestionsTable.entity_type"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; ","sortable":"","filterMatchMode":"contains","sortField":"entity_type"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"filter":"","placeholder":_vm.$t('suggestionsTable.filterBy', {
                      name: _vm.$t("suggestionsTable.entity_type"),
                    })},model:{value:(_vm.filters['entity_type']),callback:function ($$v) {_vm.$set(_vm.filters, 'entity_type', $$v)},expression:"filters['entity_type']"}})]},proxy:true},{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.getEntity(slotProps.data.entity_type))+" ")]}}])}),_c('Column',{key:"create_date",attrs:{"filterMatchMode":"contains","filterField":"create_date","field":"create_date","header":_vm.$t("suggestionsTable.create_date"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible","sortable":"","sortField":"create_date"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"placeholder":_vm.$t('suggestionsTable.filterBy', {
                      name: _vm.$t("suggestionsTable.create_date"),
                    })},model:{value:(_vm.filters['create_date']),callback:function ($$v) {_vm.$set(_vm.filters, 'create_date', $$v)},expression:"filters['create_date']"}})]},proxy:true},{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.create_date)+" ")]}}])}),_c('Column',{key:"execution_date",attrs:{"filterMatchMode":"contains","filterField":"execution_date","field":"execution_date","header":_vm.$t("suggestionsTable.execution_date"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible","sortable":"","sortField":"execution_date"},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"placeholder":_vm.$t('suggestionsTable.filterBy', {
                      name: _vm.$t("suggestionsTable.execution_date"),
                    })},model:{value:(_vm.filters['execution_date']),callback:function ($$v) {_vm.$set(_vm.filters, 'execution_date', $$v)},expression:"filters['execution_date']"}})]},proxy:true},{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.execution_date)+" ")]}}])}),_c('Column',{attrs:{"header":_vm.$t("suggestionsTable.details"),"headerStyle":"text-align: rtl","bodyStyle":"text-align: right; overflow: visible"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.getDetails(slotProps.data))+" ")]}}])}),_c('Column',{attrs:{"expander":true,"headerStyle":{'width': '3rem'}}}),(_vm.canDeleteTest && !_vm.isRecommendations)?_c('Column',{attrs:{"header":"#","headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.leaving_date === null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.askDeleteTest($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("suggestionsTable.delete")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.restoreTest($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("suggestionsTable.restore")))])])]}}],null,false,1408827034)}):_vm._e(),(_vm.isRecommendations && _vm.isAdmin)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":slotProps.data.status_type === 3 ||
					    slotProps.data.status_type === 4,"color":"error","icon":""},on:{"click":function($event){return _vm.rejectTest($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("suggestionsTable.reject")))])])]}}],null,false,4012356626)}):_vm._e(),(_vm.isRecommendations  && _vm.isAdmin)?_c('Column',{attrs:{"headerStyle":"width:1em","bodyStyle":"width:50px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					    var on = ref.on;
					    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":slotProps.data.status_type === 3 ||
					    slotProps.data.status_type === 4,"color":"success","icon":""},on:{"click":function($event){return _vm.acceptTest($event, slotProps)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-decagram")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("suggestionsTable.accept")))])])]}}],null,false,1184506198)}):_vm._e()]},proxy:true}])})]},proxy:true}])}),_c('TestDialog',{attrs:{"test":_vm.selectedToEdit,"isShow":_vm.dialog},on:{"closeDialog":function($event){_vm.dialog = false}}}),_c('ez-dialog',{attrs:{"isShow":_vm.isEzDialog,"title":_vm.$t('suggestionsTable.ezDescription')},on:{"closeDialog":function($event){_vm.isEzDialog = false},"ok":_vm.ezNumberReceive}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }