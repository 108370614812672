import { getAll } from "@/service/test";
import axios from "axios";
import {dateToServer} from "@/utils/dateFormat";

export async function getKeys() {
    return await axios.get(`/suggestions/keys`);
}

export async function getSuggestions() {

    return await axios.get(`/suggestions`);
}

export function createTest() {
}
export function updateTest() {

}

export async function updateColelSuggestions(newSuggestion) {
  return await axios.patch(`/suggestions/detail/colel`, newSuggestion);
}

export async function updatePersonSuggestions(newSuggestion) {
  return await axios.patch(`/suggestions/detail/person`, newSuggestion);
}

export function deleteTest() {

}
export async function acceptTest(data) {
  const send = {
    id : data.id,
    status_type: 3,
    execution_date: dateToServer( new Date()),
  }
  if (data.ez_number){
    send.ez_number =  data.ez_number
  }


  return await axios.patch(`/suggestions`, send);

}

export async function rejectTest(data) {
  const send = {
    id : data.id,
    status_type: 4,
    execution_date: dateToServer( new Date()),
  }

  return await axios.patch(`/suggestions`, send);
}
