<template >
  <div class="guests">
      <statisticCard
          :icon="'mdi-account-plus'"
          :color="'red'"
          :statistic="statistic.from_start_count"
          :description="'אברכים נוספים'"
      ></statisticCard >
  </div >
</template >

<script >
import statisticCard from '@/components/statisticCard.vue'

export default {
  name: "guests",
  props:{
    statistic: String
  },
  components: {
    statisticCard
  },

};
</script >
