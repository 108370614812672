<template>
  <div class="colelAccordion">
    <v-dialog
        v-model="isDialog"
        max-width="600"
        v-on:click:outside="cancelAndReset"
    >
      <Card >

        <template #title>
          <div >
            <v-icon
                large
                left
            >
              mdi-card-account-details-outline
            </v-icon>
            {{ $t('colelDialog.title') }}
          </div>

        </template>

        <template #content>
          <Accordion
              :multiple="true"
              :activeIndex="activeIndex"
          >
            <AccordionTab :header="($t('colelDialog.title'))">
              <colelDetails
                  ref="details"
                  :colel="selectedToEdit"
                  :isShow="dialog"
                  v-on:closeDialog="dialog = false"

              />
            </AccordionTab>
            <AccordionTab
                class="p-p-0"
                :header="($t('colelHours.title'))"
            >
              <colelHours
                  ref="hours"
                  :introShow="false"
                  :isShow="isHoursDialog"
                  :colelProp="selectedToEdit"
              />
            </AccordionTab>
            <AccordionTab :header="($t('colelMessage.title'))">
              <colelMessage
                  ref="message"
                  v-on:closeDialog="closeColelMessageDialog"
                  :isShow="isMessageDialog"
                  :colelProp="selectedToEdit"
              />
            </AccordionTab>
            <AccordionTab :header="($t('colelSettings.title'))">
              <colelSettings
                  ref="settings"
                  :colelProp="selectedToEdit"
              />
            </AccordionTab>
          </Accordion>
        </template>
        <template #footer>
          <div class="p-d-flex p-jc-end">
            <Button
                icon="pi pi-times"
                :label="($t('form.cancel'))"
                class="p-button-secondary "
                @click="cancelAndReset"
            />
            <Button
                @click="submit"
                icon="pi pi-check"
                :label="($t('form.ok'))"
                class="p-mr-2"
            />
          </div>

        </template>
      </Card>

    </v-dialog>


  </div>
</template>

<script>

import colelDetails from "./colelDialog/components/colelDetails.vue";
import colelHours from "./colelDialog/components/colelHours.vue";
import colelMessage from "./colelDialog/components/colelMessage.vue";
import colelSettings from "./colelDialog/components/colelSettings.vue";
import {
  canAddColel,
  canEditPerson,
  canDeletePerson
} from "@/utils/permissions.js";
import {
  contractionColelObj,
  getAllColel,
  createColel,
  acceptColel,
  rejectColel,
  contractionMessage,
  contractionHours,
  contractionToPost,
  contractionSettings
} from "./colelTable";

import {mapState} from "vuex";
import constants from "@/constants";

export default {
  name: "colelAccordion",
  props: {
    isUpdate: Boolean,
isRecommendations: Boolean,
    dialog: Boolean,
    selectedToEdit: Object,
    activeIndex: Array
  },
  components: {
    colelDetails,
    colelHours,
    colelMessage,
    colelSettings
  },
  watch: {
    dialog(newValue, oldValue) {
      // this.isDialog = newValue
    }
  },
  data() {
    return {
      componentKey: 0,
      expandedRows: [],
      // isDialog: this.dialog ,
      isDeleteDialog: false,
      isMessageDialog: false,
      isHoursDialog: false,
      titleDeleteDialog: null,
      deleteProp: null,
      selectedToDelete: null,
      filters: {},
      expandedRowGroups: null,
      isLoading: true,
      headers: headers,
      data: [],
      messageIsNotValid: true,
      detailsIsNotValid: true,
      selectedItemsTracing: []
    };
  },
  created() {
    this.getData()
    document.addEventListener("keypress", (event) => {
      if (event.key === "Enter" && this.isDialog) {
        event.preventDefault();
	   this.submit()
      }
    });
  },
  methods: {
    cancelAndReset(){
      this.close()
      this.reset()
    },
    reset() {
      this.$refs.details.cancel();
      this.$refs.hours.$refs.hours.cancel();
      this.$refs.message.cancel();
      this.$refs.settings.cancel();
    },
    close() {
      this.$emit("closeDialog");
    },
    async submit() {
      const detailsValid = await this.$refs.details.$refs.colelDetails.validate();
      const messageValid = await this.$refs.message.$refs.colelMessage.validate();

      if (detailsValid === false || messageValid === false) {
        this.$toast.add({
          severity: 'error',
          summary: this.$t(
              'form.fieldsErrorTitle'),
          life: constants.LIFE_TOAST
        });
      } else {

        const colelObj = contractionColelObj(this.$refs.details._data.colelObj)
        const message = contractionMessage(this.$refs.message._data.message)
        const hours = contractionHours(this.$refs.hours.$refs.hours._data.newHours)
        const settings = contractionSettings(this.$refs.settings._data.settings)
        const objToPost = contractionToPost(colelObj, message, hours, settings)

        try {
          const result = await createColel(objToPost, this.isUpdate);
          if (result) {
            this.$toast.add({
              severity: 'success',
              summary: this.$t(
                  'colelDialog.createSuccessfully'),
              life: constants.LIFE_TOAST
            });
            this.cancelAndReset()
            this.$emit("closeDialog");

          }
        } catch (e) {
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t(
                                'colelDialog.createError'),
                            life: constants.LIFE_TOAST
                          });
          console.log(e)
        }
      }
    },
    async getData() {
      const [error, data, options] = await getAllColel()
      if (data) {
        this.isLoading = false;
        this.data = data
      } else if (error) {
        console.log("error", error)

      }
    },
    closeColelHoursDialog() {
      this.isHoursDialog = false;
      this.selectedToEdit = null;
    },
    closeColelMessageDialog() {
      this.isMessageDialog = false;
      this.selectedToEdit = null;
    },
    rowClass(data) {
      let rowClass = "";
      data.colel.end_date !== null ? (rowClass += "row-unenabled") : null;
      return rowClass;
    },

    addColel() {
      if (canAddColel() && !this.isRecommendations) {
        // this.selectedToEdit = {};
        this.dialog = true;
      }
    },


  },
  computed: {
    isDialog: {
      get: function () {
        return this.dialog ?? false
      },
      set: function (value) {
      }

    },
    canDeletePerson() {
      return canDeletePerson(this.user);
    },
    ...mapState(["user"])
  }
};

const headers = [
  "colel.id",
  "colel.city",
  "colel.street",
  "colel.apartment",
  "colel.mail_address",
  "colel_details.owner.first_name",
  "colel_details.owner.last_name",
  "colel_details.owner.phone_number"
];

</script>

<style lang="scss">
.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
}

.p-column-filter {
  width: 100%;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
</style>
