/* eslint-disable no-unused-vars */
<template >
  <div class="colelDetails">
    <validation-observer
	    ref="colelDetails"
	    v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <v-container >
          <v-row >
            <v-col
			  cols="12"
			  xs="12"
			  sm="4"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="id"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 :disabled="colel.colel.id !== undefined "
				 @change="updateInput(invalid)"
				 v-model="colelObj.id"
				 :error-messages="errors"
				 :label="$t(`colelDialog.id`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >
            <v-col cols="12">
              <v-divider class="mx-4"></v-divider >
            </v-col >
            <v-col
			  cols="12"
			  xs="12"
			  sm="4"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="city"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 @change="updateInput(invalid)"

				 v-model="colelObj.city"
				 :error-messages="errors"
				 :label="$t(`colelDialog.city`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >
            <v-col
			  cols="12"
			  xs="12"
			  sm="8"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="street"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 @change="updateInput(invalid)"

				 v-model="colelObj.street"
				 :error-messages="errors"
				 :label="$t(`colelDialog.street`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >
            <v-col
			  cols="12"
			  xs="12"
			  sm="3"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="apartment"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 @change="updateInput(invalid)"

				 v-model="colelObj.apartment"
				 :error-messages="errors"
				 :label="$t(`colelDialog.apartment`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >

		    <!--            תיאור הכתובת-->
            <v-col
			  cols="12"
			  xs="12"
			  sm="8"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="address_description"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 @change="updateInput(invalid)"

				 v-model="colelObj.address_description"
				 :error-messages="errors"
				 :label="$t(`colelDialog.address_description`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >

		    <!--            סיסמה-->
            <v-col
			  cols="6"
			  xs="12"
			  sm="6"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="password"
			    :rules="{
                          required: true,
                        }"
		    >
                <v-text-field
				 @change="updateInput(invalid)"

				 v-model="colelObj.password"
				 :error-messages="errors"
				 :label="$t(`colelDialog.password`)"
			 ></v-text-field >
              </validation-provider >
            </v-col >

      <v-col
		 cols="12"
		 xs="12"
		 sm="3"
	 >
		<validation-provider
			v-slot="{ errors }"
			name="colel_level"
			:rules="{
                          required: true,
                        }"
		>
		 <v-text-field
			 type="number"
			 :error-messages="errors"
			 :label="$t(`רמת הכולל`)"
			 v-model="colelObj.colel_level"
			 required
		 ></v-text-field >
		</validation-provider >


		<!--        <v-select-->
		<!--		   @change="updateInput(invalid)"-->
		<!--		   :error-messages="errors"-->
		<!--		   :label="$t(`רמת הכולל`)"-->
		<!--		   v-model="colelObj.colel_level"-->
		<!--		   :items="[0,1]"-->
		<!--	   ></v-select>-->
      </v-col >
		    <!--            מנהל כולל-->
            <v-col
			  cols="6"
			  xs="12"
			  sm="6"
		  >
              <validation-provider
			    v-slot="{ errors }"
			    name="owner"
			    :rules="{
                          required: true,
                    }"
		    >
			   <v-autocomplete
				   :error-messages="errors"
				   :label="$t(`colelDetails.full_name`)"
				   clearable
				   item-text="displayName"
				   item-value="id"
				   v-model="colelObj.owner"
				   :items="persons"
				   dense
			   ></v-autocomplete >
              </validation-provider >

            </v-col >
    </v-row >
        </v-container >

      </form >
    </validation-observer >
  </div >
</template >

<script >
import {required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import i18n from "@/translate/translate";
import {getAllPerson, updateColel} from "../../colelTable";


extend("required", {
  ...required,
  message: i18n.t(`form.required`)
});

export default {
  name: "colelDetails",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    isShow: Boolean,
    colel: Object
  },
  data() {
    return {
      valid: false,
      colelObj: {colel_level: 1},
      persons: null
    };
  },
  created() {
    if (this.colel.colel) {
      this.colelObj = {...this.colel.colel}
    }
    this.fillPersonDropDown()
  },
  watch: {
    colel(newProp) {
      if (newProp?.colel?.id) {

        // this.updateInput()
        this.colelObj = {...newProp.colel};
      }

    }
  },
  methods: {
    async fillPersonDropDown() {
      try {
        const {data} = await getAllPerson()
        data.map(p => {
          p.displayName = `${p.first_name} ${p.last_name}  - ${p.id}`
        })
        this.persons = data
      } catch (e) {
        console.log(e)
      }

    },
    async updateInput() {
      this.$emit("updateInput")
    },
    cancel() {
      this.colelObj = {}
      this.$refs.colelDetails.reset();
    },
    submit() {
      this.$refs.colelDetails.validate();
      this.$emit("closeDialog");
      this.$refs.colelDetails.reset();
      if (this.colel.colel.id) {
        delete this.colelObj.phone_number
        this.$emit("updateColel", this.colelObj);
        this.colelObj = {}

      } else {
        this.$emit("closeDialog");
        delete this.colelObj.phone_number
        this.$emit("createColel", this.colelObj);
        this.colelObj = {}
      }

    }
  }
};
</script >
