<template >

  <div class="statistics p-grid p-p-3" v-if="statistics" >
	<div class="p-col-12 p-md-6 p-lg-3">
      <hours :statistic="statistics.hours" class="p-p-1"/>
	</div >
          <div class="p-col-12 p-md-6 p-lg-3">
      <articles :statistic="statistics.test" class="p-p-1"/>
	</div >
          <div class="p-col-6 p-md-6 p-lg-3">
      <persons  :statistic="statistics.persons_count"  class="p-p-1"/>
	</div >
	<div class="p-col-6 p-md-6 p-lg-3">
      <guests :statistic="statistics.guests" class="p-p-1"/>
	</div >

    </div >
</template >

<script >
import guests from './statisticCards/guests'
import persons from './statisticCards/persons'
import hours from './statisticCards/hours'
import articles from './statisticCards/articles'
import {postGuestStatistic} from "@/views/home/statisticCards";
import { mapGetters} from "vuex";
export default {
  name: "Home",
  data() {
    return {
      statistics: null,
    }
  },
  created() {
    this.init()
  },
  watch: {
    isAdmin(newValue, oldValue) {
	 this.init()
    },
    colelId(newValue, oldValue) {
      this.init()
    }
  },
  components: {
    guests,
    persons,
    articles,
    hours
  },
  methods:{
    async init() {
      if (this.colelId !== undefined && this.isAdmin !== undefined) {
        try {
          const {data: statistics} = await postGuestStatistic(this.colelId,
                                                        this.isAdmin)
          this.statistics = statistics

        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  computed: {
  ...mapGetters(["isAdmin",'colelId']),
  },
};
</script >
