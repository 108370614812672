<template >
  <div class="persons">
      <statisticCard
          :icon="'mdi-account-supervisor-circle'"
          :color="'blue'"
          :statistic="statistic"
          :description="'אברכים קבועים'"
      ></statisticCard >
  </div >
</template >

<script >
import statisticCard from '@/components/statisticCard.vue'

export default {
  name: "persons",
  components: {
    statisticCard
  },
  props:{
    statistic: String
  },
  methods: {
    getCount({hours, minutes, seconds}) {
      return `${hours}:${minutes}:${seconds}`
    }
  }
};
</script >
