
<template>
  <div class="suggestions">
    <div class="p-col-12 p-md-12 p-lg-12 p-p-3 p-p-lg-0">
<Home/>
        <suggestionsTable class="p-p-2" isRecommendations />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import suggestionsTable from "./suggestionsTable/suggestionsTable.vue";
import Home from "@/views/home/Home";

export default {
  name: "Suggestions",
  data() {
    return {
      date: new Date()
    };
  },
  components: {
    Home,
    suggestionsTable
  },
  computed: {
    ...mapState(["user", "colel"]),
  }
};
</script>
