<template >
  <div class="statisticCard p-shadow-1 p-grid " >
<!--      <Card >-->
<!--    <template #content>-->

          <div class="p-col-8 p-p-1">
            <div class="p-grid p-mr-3 p-ml-1">
              <div class="p-col-12 p-mt-2 p-p-0">
                <h1 >
                           {{ statistic }}
                </h1 >
              </div >

              <div class="p-col-12  p-mb-2   p-p-0">
                <div class="box">
                    {{ description }}
                    </div >
              </div >
            </div >
          </div >
          <div class="p-col-4 p-d-flex   p-pl-3 p-ai-center p-jc-center">
<!--            <div class="box box-stretched">-->
                <v-icon
                    x-large

                   :color="color"
                    style="font-size: 3rem"
                >
        {{icon}}
      </v-icon>
<!--            </div >-->
          </div >
  </div >
</template >

<script >

export default {
  name: "statisticCard",

  props: {
    icon: String,
    statistic: String,
    description: String,
    color: String

  }
  // data() {
  //   return {
  //     valid: false,
  //     personObj: null,
  //   };
  // },
  // watch: {
  //   person(newPerson) {
  //     this.personObj = newPerson;
  //   },
  // },
  // methods: {
  //   clodeDialog() {
  //     this.$emit("closeDialog");
  //   },
  //   ok() {
  //     this.$emit("ok");
  //     this.$emit("closeDialog");
  //   },
  // },
};
</script >
