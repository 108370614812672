<template >
  <div class="hours">
      <statisticCard
          :icon="'mdi-clock-time-two'"
          :color="'green'"
		:statistic="getCount(statistic.from_start_count)"
		:description="'שעות לימוד'"
      ></statisticCard >
  </div >
</template >

<script >
import statisticCard from '@/components/statisticCard.vue'

export default {
  name: "hours",
  components: {
    statisticCard
  },
  props:{
    statistic: String
  },
  methods: {
    getCount(from_start_count) {
       const {hours, minutes, seconds} = from_start_count
      if (hours && minutes && seconds) {
        return `${hours}}`
        // return `${hours}:${minutes}:${seconds}`
      } else if (hours && minutes) {
        return `${hours}`
        // return `${hours}:${minutes}`
      } else {
        return 0
      }
    }
  }
};
</script >
