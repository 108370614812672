<template>
  <div class="colelHoursDialog">
                  <colelHours
				   :deepCopy="false"
				   :changes="changes"
				   ref="hours"
                 :colelProp="colelProp"
                 v-on:close="isShow = false"
                 v-on:newHours="newHours"
                 v-on:updateHour="updateColelSuggestion"
               />

  </div>
</template>

<script>
import colelHours from "@/views/introColelSettings/colelHours/colelHours.vue";


export default {
  name: "colelHoursDialog",
  components: {
    colelHours
    
  },
  props: {
    changes: Object,
    isShow: Boolean,
    colelProp: Object,
  },

  data() {
    return {
      colel: null,
    };
  },
  watch: {
    colelProp(newColel) {
      this.colel = newColel;
    },
  },
  methods: {
    clodeDialog() {
      this.$emit("closeDialog");
    },
    newHours(e){
      this.$emit("newHours", e)
    },
    updateColelSuggestion(e){
      console.log("^^^^^", e)
      this.$emit("updateColelSuggestion", e);
    }
  },
};
</script>
