/* eslint-disable no-unused-vars */
<template>
  <div class="testDialog">
    <v-dialog v-model="isShow" max-width="600" v-on:click:outside="clodeDialog">
      <colel-card
        :title="$t('testDialog.title')"
        :icon="'mdi-card-account-details-outline'"
      >
        <template v-slot:content>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.last_name`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.last_name"
                          :error-messages="errors"
                          :label="$t(`personTable.last_name`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.first_name`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.first_name"
                          :error-messages="errors"
                          :label="$t(`personTable.first_name`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.first_name`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.phone_number"
                          :error-messages="errors"
                          :label="$t(`personTable.phone_number`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.id`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.id"
                          :error-messages="errors"
                          :label="$t(`personTable.id`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="mx-4"></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.street`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.street"
                          :error-messages="errors"
                          :label="$t(`personTable.street`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="2">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.apartment`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.apartment"
                          :error-messages="errors"
                          :label="$t(`personTable.apartment`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.city`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.city"
                          :error-messages="errors"
                          :label="$t(`personTable.city`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="mx-4"></v-divider>
                    </v-col>

                    <v-col cols="12" xs="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.bank_name`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.bank_name"
                          :error-messages="errors"
                          :label="$t(`personTable.bank_name`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="2">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.bank_branch`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.bank_branch"
                          :error-messages="errors"
                          :label="$t(`personTable.bank_branch`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.bank_account`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.bank_account"
                          :error-messages="errors"
                          :label="$t(`personTable.bank_account`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.name_to_pay_for`)"
                        rules="required"
                      >
                        <v-text-field
                          v-model="personObj.name_to_pay_for"
                          :error-messages="errors"
                          :label="$t(`personTable.name_to_pay_for`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t(`personTable.ez_number`)"
                        :rules="{
                          required: true,
                          regex: '^[0-9]*$',
                        }"
                      >
                        <v-text-field
                          v-model="personObj.ez_number"
                          :error-messages="errors"
                          :label="$t(`personTable.ez_number`)"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="clodeDialog">
                  {{ $t("testDialog.cancel") }}
                </v-btn>
                <v-btn
                  type="submit"
                  :disabled="invalid"
                  color="blue darken-1"
                  text
                  @click="post"
                  >{{ $t("testDialog.post") }}</v-btn
                >
              </v-card-actions>
            </form>
          </validation-observer>
        </template>
      </colel-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
// eslint-disable-next-line no-unused-vars
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import i18n from "@/translate/translate";
import {update} from "../suggestionsTable";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: i18n.t(`form.required`),
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: i18n.t(`form.regex`),
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  name: "testDialog",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isShow: Boolean,
    person: Object,
  },

  data() {
    return {
      valid: false,
      personObj: null,
    };
  },
  watch: {
    person(newPerson) {
      this.personObj = newPerson;
    },
  },
  methods: {
    clodeDialog() {
      this.$refs.observer.reset();
      this.$emit("closeDialog");
    },
    post() {
      this.$refs.observer.validate();
     update(this.personObj)
      this.$emit("closeDialog");
    },
  },
};
</script>
