<template>
  <div class="colelHoursDialog">

                  <colelHours
				   ref="hours"
                 :colelProp="colelProp"
                 v-on:close="isShow = false"
                 v-on:newHours="newHours"
               />

<!--    <v-dialog style="z-index: 1030" v-model="isShow" max-width="600"-->
<!--              v-on:click:outside="clodeDialog">-->
<!--       <colelHours-->
<!--      :colelProp="colel"-->
<!--      v-on:close="isShow = false"-->
<!--      v-on:newHours="newHours"-->
<!--    />-->
    
<!--    </v-dialog>-->
  </div>
</template>

<script>
import colelHours from "@/views/introColelSettings/colelHours/colelHours.vue";


export default {
  name: "colelHoursDialog",
  components: {
    colelHours
    
  },
  props: {
    isShow: Boolean,
    colelProp: Object,
  },

  data() {
    return {
      colel: null,
    };
  },
  watch: {
    colelProp(newColel) {
      this.colel = newColel;
    },
  },
  methods: {
    clodeDialog() {
      this.$emit("closeDialog");
    },
    newHours(e){
      this.$emit("newHours", e)
    }
  },
};
</script>
