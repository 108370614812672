var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colelDetails"},[_c('validation-observer',{ref:"colelDetails",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"id","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.colel.colel.id !== undefined,"error-messages":errors,"label":_vm.$t("colelDialog.id")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.id),callback:function ($$v) {_vm.$set(_vm.colelObj, "id", $$v)},expression:"colelObj.id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-4"})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"city","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelDialog.city")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.city),callback:function ($$v) {_vm.$set(_vm.colelObj, "city", $$v)},expression:"colelObj.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"8"}},[_c('validation-provider',{attrs:{"name":"street","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelDialog.street")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.street),callback:function ($$v) {_vm.$set(_vm.colelObj, "street", $$v)},expression:"colelObj.street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"apartment","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelDialog.apartment")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.apartment),callback:function ($$v) {_vm.$set(_vm.colelObj, "apartment", $$v)},expression:"colelObj.apartment"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"8"}},[_c('validation-provider',{attrs:{"name":"address_description","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelDialog.address_description")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.address_description),callback:function ($$v) {_vm.$set(_vm.colelObj, "address_description", $$v)},expression:"colelObj.address_description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","xs":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"password","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelDialog.password")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.colelObj.password),callback:function ($$v) {_vm.$set(_vm.colelObj, "password", $$v)},expression:"colelObj.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"colel_level","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","error-messages":errors,"label":_vm.$t("רמת הכולל"),"required":""},model:{value:(_vm.colelObj.colel_level),callback:function ($$v) {_vm.$set(_vm.colelObj, "colel_level", $$v)},expression:"colelObj.colel_level"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","xs":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"owner","rules":{
                          required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":_vm.$t("colelDetails.full_name"),"clearable":"","item-text":"displayName","item-value":"id","items":_vm.persons,"dense":""},model:{value:(_vm.colelObj.owner),callback:function ($$v) {_vm.$set(_vm.colelObj, "owner", $$v)},expression:"colelObj.owner"}})]}}],null,true)})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }