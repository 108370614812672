var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"testDialog"},[_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":_vm.clodeDialog},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('colel-card',{attrs:{"title":_vm.$t('testDialog.title'),"icon":'mdi-card-account-details-outline'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.last_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.last_name")},model:{value:(_vm.personObj.last_name),callback:function ($$v) {_vm.$set(_vm.personObj, "last_name", $$v)},expression:"personObj.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.first_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.first_name")},model:{value:(_vm.personObj.first_name),callback:function ($$v) {_vm.$set(_vm.personObj, "first_name", $$v)},expression:"personObj.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.first_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.phone_number")},model:{value:(_vm.personObj.phone_number),callback:function ($$v) {_vm.$set(_vm.personObj, "phone_number", $$v)},expression:"personObj.phone_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.id"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.id")},model:{value:(_vm.personObj.id),callback:function ($$v) {_vm.$set(_vm.personObj, "id", $$v)},expression:"personObj.id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-4"})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.street"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.street")},model:{value:(_vm.personObj.street),callback:function ($$v) {_vm.$set(_vm.personObj, "street", $$v)},expression:"personObj.street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.apartment"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.apartment")},model:{value:(_vm.personObj.apartment),callback:function ($$v) {_vm.$set(_vm.personObj, "apartment", $$v)},expression:"personObj.apartment"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.city"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.city")},model:{value:(_vm.personObj.city),callback:function ($$v) {_vm.$set(_vm.personObj, "city", $$v)},expression:"personObj.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-4"})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.bank_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.bank_name")},model:{value:(_vm.personObj.bank_name),callback:function ($$v) {_vm.$set(_vm.personObj, "bank_name", $$v)},expression:"personObj.bank_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.bank_branch"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.bank_branch")},model:{value:(_vm.personObj.bank_branch),callback:function ($$v) {_vm.$set(_vm.personObj, "bank_branch", $$v)},expression:"personObj.bank_branch"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.bank_account"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.bank_account")},model:{value:(_vm.personObj.bank_account),callback:function ($$v) {_vm.$set(_vm.personObj, "bank_account", $$v)},expression:"personObj.bank_account"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.name_to_pay_for"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.name_to_pay_for")},model:{value:(_vm.personObj.name_to_pay_for),callback:function ($$v) {_vm.$set(_vm.personObj, "name_to_pay_for", $$v)},expression:"personObj.name_to_pay_for"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t("personTable.ez_number"),"rules":{
                        required: true,
                        regex: '^[0-9]*$',
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("personTable.ez_number")},model:{value:(_vm.personObj.ez_number),callback:function ($$v) {_vm.$set(_vm.personObj, "ez_number", $$v)},expression:"personObj.ez_number"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.clodeDialog}},[_vm._v(" "+_vm._s(_vm.$t("testDialog.cancel"))+" ")]),_c('v-btn',{attrs:{"type":"submit","disabled":invalid,"color":"blue darken-1","text":""},on:{"click":_vm.post}},[_vm._v(_vm._s(_vm.$t("testDialog.post")))])],1)],1)]}}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }