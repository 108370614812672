import axios from "axios";
import {dateToServer} from "@/utils/dateFormat";
const REPORTER_ROLE = 1;
export async function getAllColel() {
  try {
    const {data, headers} = await axios.get(`/manager/colel/all`);

    return [null, data, {headers}];
  } catch (error) {
    console.error(error);
    return [error, error.response?.status];
  }
}

export async function getAllStatuses(date) {
    return axios.get(
        `/attendance/status/${date.getFullYear()}/${date.getMonth() + 1}`);
}

export async function changeManagerLockStatus(date, colel_id) {
  const requestBody = {
    colel_id: colel_id,
    month: date.getMonth() + 1,
    year: date.getFullYear()
  }
  const {data: {is_manager_lock}} = await axios.post(`/manager/attendance/lock`,
      requestBody)
  return is_manager_lock
}


export async function getAllPerson() {
  return await axios.get(`/manager/person/all`);
}


export async function updateColel(colel) {
  try {
    const {data} = await axios.patch(`/manager/colel`, colel);
    return {data};
  } catch (error) {
    return {error, status: error.response?.status};
  }
}

export async function deleteColel(colelId) {
  return await axios.delete(`/manager/colel/${colelId}`);
}

export async function createColel(colel, isUpdate) {
  if (isUpdate) {
    return await axios.patch(`/manager/colel`, colel);
  } else {

    return await axios.post(`/manager/colel`, colel);
  }
}

export async function restoreColel(colelId) {
  return await axios.post(`/manager/colel/return/${colelId}`);
}

export function contractionMessage(message) {
  // message.message_color = message.message_color.value
  return message
}

export function contractionColelObj(colelObj) {
  return colelObj
}

export function contractionHours(hours) {
  return hours
}

export function contractionSettings(settings) {
  return settings
}

export function contractionToPost(colelObj, message, hours, settings) {
  if (settings.manager_lock === undefined) {
    settings.manager_lock = false
  }
  const permissions = {
    colel_id: colelObj.id,
    user_name: colelObj.id,
    password: colelObj.password + '',
    role: REPORTER_ROLE + ''
  }

  delete colelObj.permissions
  return {
    colel: {
      ...colelObj,
      message_color: message.message_color,
      special_message_content: message.special_message_content,
      special_message_title: message.special_message_title,
      join_date: dateToServer(new Date()),
      colel_level: colelObj.colel_level,
      hours: colelObj.id,
      owner: colelObj.owner.id ? colelObj.owner.id : colelObj.owner
    },
    colel_details: {
      hours,
      settings,
      permissions
    }
  }
}

function acceptColel(colel) {
}

function rejectColel(colel) {
}


