<template >
  <div class="suggestionsTable">
    <colel-card
	    :title="(!isRecommendations ? $t('suggestionsTable.title'): $t('suggestionsTable.titleRecommendations'))"
	    :isLoading="isLoading"
	    :icon="'mdi-account-supervisor-circle'"
    >
      <template v-slot:extra-header>
        <v-tooltip
		   bottom
		   v-if="!isRecommendations"
	   >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
			  @click="addTest"
			  color="primary"
			  icon
			  v-bind="attrs"
			  v-on="on"
		  >
              <v-icon >mdi-plus-circle</v-icon >
            </v-btn >
          </template >
          <span >{{ $t("testTable.add") }}</span >
        </v-tooltip >
      </template >
      <template v-slot:content>
        <colel-table
		   v-on:row-click="rowClick"
		   :value="dataSorted"
		   :tableId="'suggestions-data-state'"
		   :filters="filters"
		   :loading="isLoading"
		   :loadingTitle="$t(`testTable.loading`)"
		   :rowClass="rowClass"
		   :expandedRows="expandedRows"
		   :rows="dataActiveLength"
		   sortField="status_type"
	   >
		   <template v-slot:tableExpansion="slotProps">
			  <div
				  class="p-grid p-jc-center"
				  v-if="slotProps.slotProps.entity_type
			  === 1"
				  style="background: #e7eced8a;"
			  >
				 <colelDetails
					 class="p-col-12 p-md-6 p-lg-5"
					 ref="details"
					 :colel="slotProps.slotProps.new_entity"
					 :changesObj="slotProps.slotProps.changes"
					 v-on:updateColelSuggestion="updateColelSuggestion($event, slotProps.slotProps)"
				 />


				 	<colelHours
						class="p-col-12 p-md-6 p-lg-5"

						:introShow="false"
@updateColelSuggestion="updateHoursSuggestion($event, slotProps.slotProps)"
						:colelProp="slotProps.slotProps.new_entity"
						:changes="slotProps.slotProps.changes.hours"

					/>

			  </div >

			  	  <div
					  v-if="slotProps.slotProps.entity_type === 2"
					  style="background: #e7eced8a;"
				  >
				   <personDetails
					   :person="slotProps.slotProps.new_entity"
					   :changes="slotProps.slotProps.changes"
					   @updatePersonSuggestion="updatePersonSuggestion($event, slotProps.slotProps)"
				   />
			  </div >
		   </template >
          <template v-slot:tableContent>
		      <Column
				 v-if="isRecommendations"
				 headerStyle="width:1em"
				 bodyStyle="width:50px; text-align: center"
				 sortable
				 sortField="suggestion_type"
			 >
              <template #body="slotProps">

                   <i :class="typeIcon(slotProps.data.suggestion_type)"/>

              </template >
            </Column >
		       <Column
				  filterMatchMode="contains"
				  filterField="status_type"
				  key="status_type"
				  field="status_type"
				  :header="$t(`suggestionsTable.status_type`)"
				  headerStyle="text-align: rtl"
				  bodyStyle="text-align: right; overflow: visible"
				  sortable
				  sortField="status_type"
			  >
			 <template #body="slotProps">
<div
	:class="{
  'status-accept':
slotProps.data.status_type === 3,
'status-reject':
slotProps.data.status_type === 4,
'status-suspend':
slotProps.data.status_type === 2,
'status-unread':
slotProps.data.status_type === 1}
"
>

				{{ getStatus(slotProps.data.status_type) }}
</div >

              </template >
            </Column >
            <Column
			  key="colel_id"
			  field="colel_id"
			  :header="$t(`suggestionsTable.colel_id`)"
			  headerStyle="text-align: rtl"
			  bodyStyle="text-align: right; overflow: visible"
			  sortable
			  sortField="colel_id"
			  filterMatchMode="contains"
		  >
              <template #filter>
                <InputText
				 filter
				 v-model="filters['colel_id']"
				 class="p-column-filter"
				 :placeholder="
                    $t('suggestionsTable.filterBy', {
                      name: $t(`suggestionsTable.colel_id`),
                    })
                  "
			 />
              </template >
            </Column >

		    <!--            <Column-->
		    <!--			  key="suggestion_type"-->
		    <!--			  field="suggestion_type"-->
		    <!--			  :header="$t(`suggestionsTable.suggestion_type`)"-->
		    <!--			  headerStyle="text-align: rtl"-->
		    <!--			  bodyStyle="text-align: right; overflow: visible"-->
		    <!--			  sortable-->
		    <!--			  sortField="suggestion_type"-->
		    <!--		  >-->
		    <!--              <template #filter>-->
		    <!--                <InputText-->
		    <!--				 filter-->
		    <!--				 v-model="filters['suggestion_type']"-->
		    <!--				 class="p-column-filter"-->
		    <!--				 :placeholder="-->
		    <!--                    $t('suggestionsTable.filterBy', {-->
		    <!--                      name: $t(`suggestionsTable.suggestion_type`),-->
		    <!--                    })-->
		    <!--                  "-->
		    <!--			 />-->
		    <!--              </template >-->
		    <!--			 <template #body="slotProps">-->

		    <!--				{{ getSuggestionType(slotProps.data.suggestion_type) }}-->

		    <!--              </template >-->
		    <!--            </Column >-->
		    <Column
			    key="entity_type"
			    field="entity_type"
			    :header="$t(`suggestionsTable.entity_type`)"
			    headerStyle="text-align: rtl"
			    bodyStyle="text-align: right; "
			    sortable
			    filterMatchMode="contains"
			    sortField="entity_type"
		    >
              <template #filter>
                <InputText
				 filter
				 v-model="filters['entity_type']"
				 class="p-column-filter"
				 :placeholder="
                    $t('suggestionsTable.filterBy', {
                      name: $t(`suggestionsTable.entity_type`),
                    })
                  "
			 />
              </template >
			 <template #body="slotProps">

				{{ getEntity(slotProps.data.entity_type) }}

              </template >
            </Column >

		    <Column
			    filterMatchMode="contains"
			    filterField="create_date"
			    key="create_date"
			    field="create_date"
			    :header="$t(`suggestionsTable.create_date`)"
			    headerStyle="text-align: rtl"
			    bodyStyle="text-align: right; overflow: visible"
			    sortable

			    sortField="create_date"
		    >
              <template #filter>
                <InputText

				 v-model="filters['create_date']"
				 class="p-column-filter"
				 :placeholder="
                    $t('suggestionsTable.filterBy', {
                      name: $t(`suggestionsTable.create_date`),
                    })
                  "
			 />
              </template >
			 <template #body="slotProps">

				{{ slotProps.data.create_date }}

              </template >
            </Column >
		    <Column
			    filterMatchMode="contains"
			    filterField="execution_date"
			    key="execution_date"
			    field="execution_date"
			    :header="$t(`suggestionsTable.execution_date`)"
			    headerStyle="text-align: rtl"
			    bodyStyle="text-align: right; overflow: visible"
			    sortable
			    sortField="execution_date"
		    >
              <template #filter>
                <InputText

				 v-model="filters['execution_date']"
				 class="p-column-filter"
				 :placeholder="
                    $t('suggestionsTable.filterBy', {
                      name: $t(`suggestionsTable.execution_date`),
                    })
                  "
			 />
              </template >
			 <template #body="slotProps">

				{{ slotProps.data.execution_date }}

              </template >
            </Column >
		    <Column

			    :header="$t(`suggestionsTable.details`)"
			    headerStyle="text-align: rtl"
			    bodyStyle="text-align: right; overflow: visible"

		    >
<!--              <template #filter>-->
<!--                <InputText-->

<!--				 v-model="filters['execution_date']"-->
<!--				 class="p-column-filter"-->
<!--				 :placeholder="-->
<!--                    $t('suggestionsTable.filterBy', {-->
<!--                      name: $t(`suggestionsTable.execution_date`),-->
<!--                    })-->
<!--                  "-->
<!--			 />-->
<!--              </template >-->
			 <template #body="slotProps">
{{ getDetails(slotProps.data) }}


              </template >
            </Column >
 <Column
	 :expander="true"
	 :headerStyle="{'width': '3rem'}"
 />

            <Column
			  v-if="canDeleteTest && !isRecommendations"
			  header="#"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"
		  >
              <template #body="slotProps">
                    <v-tooltip
					bottom
					v-if="slotProps.data.leaving_date === null"
				>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn

					    @click="askDeleteTest($event, slotProps)"
					    color="error"
					    icon
					    v-bind="attrs"
					    v-on="on"
				    >
                          <v-icon >mdi-trash-can</v-icon >
                        </v-btn >
                      </template >
                      <span >{{ $t("suggestionsTable.delete") }}</span >
                    </v-tooltip >
                           <v-tooltip
						  bottom
						  v-else
					  >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn

					    @click="restoreTest($event, slotProps)"
					    color="success"
					    icon
					    v-bind="attrs"
					    v-on="on"
				    >
                          <v-icon >mdi-backup-restore</v-icon >
                        </v-btn >
                      </template >
                      <span >{{ $t("suggestionsTable.restore") }}</span >
                    </v-tooltip >
              </template >
            </Column >
           
            <Column
			  v-if="isRecommendations && isAdmin"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"
		  >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					:disabled="slotProps.data.status_type === 3 ||
					    slotProps.data.status_type === 4"
					@click="rejectTest($event, slotProps)"
					color="error"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-close-box</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("suggestionsTable.reject") }}</span >
                </v-tooltip >
              </template >
            </Column >
            <Column
			  v-if="isRecommendations  && isAdmin"
			  headerStyle="width:1em"
			  bodyStyle="width:50px"
		  >
              <template #body="slotProps">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
					:disabled="slotProps.data.status_type === 3 ||
					    slotProps.data.status_type === 4"
					@click="acceptTest($event, slotProps)"
					color="success"
					icon
					v-bind="attrs"
					v-on="on"
				>
                      <v-icon >mdi-check-decagram</v-icon >
                    </v-btn >
                  </template >
                  <span >{{ $t("suggestionsTable.accept") }}</span >
                </v-tooltip >
              </template >
            </Column >

          </template >
        </colel-table >
      </template >
    </colel-card >
    <TestDialog
	    :test="selectedToEdit"
	    :isShow="dialog"
	    v-on:closeDialog="dialog = false"
    />
    <ez-dialog
	    v-on:closeDialog="isEzDialog = false"
	    v-on:ok="ezNumberReceive"
	    :isShow="isEzDialog"
	    :title="$t('suggestionsTable.ezDescription')"
    />

  </div >
</template >

<script >

const headers = [
  "colel_id",
  "entity_type",
  "status_type",
  "suggestion_type",
  "create_date",
  "execution_date"
];
import personDetails from "./suggestionDialog/personDetails";

import ColelAccordion from "@/views/colel/colelTable/colelAccordion";
// import colelDetails from "./colelDialog/components/colelDetails.vue";
import colelDetails from "./suggestionDialog/colelDetails";
import colelHours from "./suggestionDialog/colelHours";
import ezDialog from "./suggestionDialog/ezDialog";

import TestDialog from "./suggestionDialog/testDialog.vue";
import {canAddTest, canEditTest, canDeleteTest} from "@/utils/permissions.js";
import {
  createTest, getKeys, deleteTest, acceptTest,
  rejectTest, getSuggestions, updateColelSuggestions, updatePersonSuggestions
} from "./suggestionsTable";
import {mapGetters, mapState} from "vuex";
import i18n from "@/translate/translate";

export default {
  name: "suggestionsTable",
  props: {
    isRecommendations: Boolean
  },
  components: {
    // ColelAccordion,
    ezDialog,
    personDetails,
    colelHours,
    TestDialog,
    colelDetails
  },
  data() {
    return {
      isEzDialog: false,
      colel_id: 'חיפה',
      month: 8,
      year: 2025,
      isDeleteDialog: false,
      titleDeleteDialog: null,
      dialog: false,
      deleteProp: null,
      selectedToDelete: null,
      filters: {},
      acceptSelected: {},
      expandedRows: [],
      expandedRowGroups: null,
      isLoading: true,
      selectedToEdit: null,
      headers: headers,
      data: [],
      selectedItemsTracing: [],
      suggestion_types: null,
      entity_types: null,
      suggestion_statuses: null

    };
  },
  mounted() {
    this.init()
  },
  methods: {
    typeIcon(type) {
      switch (type) {
        case 1:
          return 'pi pi-pencil'
        case 2:
          return 'pi pi-trash'
        case 3:
          return 'pi pi-plus'
      }

    },
    getDetails(suggestsion) {
      if (suggestsion.entity_type === 1) {
        const owner = suggestsion.new_entity.colel_details.owner
        return owner.first_name + " " + owner.last_name
      } else if (suggestsion.entity_type === 2) {
        return suggestsion.new_entity.first_name + " " +
            suggestsion.new_entity.last_name
      }
    },
    getStatus(status) {
      return this.suggestion_statuses.find(s =>
                                               s.suggestion_status_key
                                               ===
                                               status).suggestion_status_value
    },
    getEntity(type) {
      return this.entity_types.find(s =>
                                        s.entity_type_key
                                        === type).entity_type_value
    },
    getSuggestionType(type) {
      return this.suggestion_types.find(s =>
                                            s.suggestion_type_key
                                            === type).suggestion_type_value
    },
    async init() {
      await this.getKeys()
      await this.getData()
    },
    async getKeys() {
      try {
        const {data} = await getKeys()
        const {
          suggestion_types,
          entity_types,
          suggestion_statuses
        } = data.suggestions_keys
        this.suggestion_types = suggestion_types
        this.entity_types = entity_types
        this.suggestion_statuses = suggestion_statuses

      } catch (e) {
        console.log(e)
      }
    },
    async getData() {
      try {

        const {data} = await getSuggestions()
        this.data = data.suggestions
        this.isLoading = false;

      } catch (e) {
        console.log(e)
      }

    },
    rowClass(data) {
      let rowClass = "";
      if (data.status_type === 3 || data.status_type === 4) {
        rowClass += "row-unenabled"
      }
      // data.leaving_date !== null ? rowClass += "row-unenabled" : null;
      return rowClass;
    },
    rowClick(e) {
      if (canEditTest(this.user) && !this.isRecommendations) {
        this.selectedToEdit = {...e.data};
        this.dialog = true;
      }
    },
    addTest() {
      if (canAddTest(this.user) && !this.isRecommendations) {
        this.selectedToEdit = {};
        this.dialog = true;
      }
    },
    askDeleteTest(e, props) {
      this.selectedToDelete = {...props.data};
      this.isDeleteDialog = true;
      this.titleDeleteDialog = i18n.t(`testTable.askDelete`, {
        first_name: this.selectedToDelete?.first_name,
        last_name: this.selectedToDelete?.last_name
      });
      e.stopPropagation();
    },
    restoreTest(e, props) {

      createTest(props.data);

      e.stopPropagation();
    },
    deleteTest() {
      deleteTest(this.selectedToDelete);
    },
    async rejectTest(e, props) {
      e.stopPropagation();
      try {
        await rejectTest(props.data);
        await this.getData()

      } catch (e) {
        console.log(e)
      }
      rejectTest(props.data);
    },
    async ezNumberReceive(input) {
      this.acceptSelected.ez_number = input
      try {
        await acceptTest(this.acceptSelected);
        await this.getData()

      } catch (e) {
        console.log(e)
      }
    },
    updateColelSuggestion(field, entity){
      if (entity.changes === undefined) entity.changes = {}
      entity.changes = {...entity.changes, [field] : true}
      updateColelSuggestions(entity)
    },
    updateHoursSuggestion(a, entity){
      entity.changes = {...entity.changes}
      entity.changes.hours = {...entity.changes.hours}
      entity.changes.hours = {...entity.changes.hours,  [a] : true }
      updateColelSuggestions(entity)
    },
    updatePersonSuggestion(field, entity){
      console.log("field",field)
      console.log("entity",entity)
      entity.changes = {...entity.changes}
      entity.changes = {...entity.changes,  [field] : true }

      updatePersonSuggestions(entity)
    },
    async acceptTest(e, props) {
      e.stopPropagation();
      if (props.data.entity_type === 2 && props.data.suggestion_type === 3) {
        this.acceptSelected = props.data
        this.isEzDialog = true

      } else {
        try {
          await acceptTest(props.data);
          await this.getData()
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  computed: {
    dataActiveLength(){
      return this.data.filter(s => s.status_type === 1).length
    },
    dataSorted(){
      return this.data.slice().sort((curr, next ) =>{
         return curr.status_type >= next.status_type ? 1 : -1
	 })
    },
    canDeleteTest() {
      return canDeleteTest(this.user);
    },
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"])
  }
};
</script >


<style lang="scss">
.status-reject {
  background: rgba(231, 168, 168, 0.78)
}

.status-accept {
  background: rgba(171, 231, 171, 0.76)
}

.status-unread {
  background: rgba(243, 233, 163, 0.87)
}

.status-suspend {
  background: rgba(232, 228, 201, 0.87)
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
}

.p-column-filter {
  width: 100%;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
</style >
