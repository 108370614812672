/* eslint-disable no-unused-vars */
<template >
  <div class="personDetails">
              <v-card-text class="p-d-flex p-flex-row-reverse">
                  <v-row class=" p-col-3">

                    <v-col
					cols="12"
					class="p-p-0"
				>

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('last_name')"
					    v-model="personObj.last_name"
					    :class="{'change': changes.last_name}"
					    :label="$t(`personTable.last_name`)"
				    ></v-text-field >
                    </v-col >
                    <v-col
					cols="12"
					class="p-p-0"
				>

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('first_name')"
					    v-model="personObj.first_name"
					    :class="{'change': changes.first_name}"
					    :label="$t(`personTable.first_name`)"
				    ></v-text-field >
                    </v-col >
					    <v-col
						    cols="12"
						    class="p-p-0"
					    >

                        <v-text-field
					    class="p-p-0"


					    :disabled="person.id !== undefined"
					    v-model="personObj.id"
					    :class="{'change': changes.id}"
					    :label="$t(`personTable.id`)"
				    ></v-text-field >
                    </v-col >
                    <v-col
					cols="12"
					class="p-p-0"
				>

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('phone_number')"
					    v-model="personObj.phone_number"
					    :class="{'change': changes.phone_number}"
					    :label="$t(`personTable.phone_number`)"
				    ></v-text-field >
                    </v-col >
  <v-col
	  cols="12"
	  class="p-p-0"
  >

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('street')"
					    v-model="personObj.street"
					    :class="{'change': changes.street}"
					    :label="$t(`personTable.street`)"
				    ></v-text-field >
                    </v-col >
                    <v-col
					cols="12"
					class="p-p-0"
				>

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('apartment')"
					    v-model="personObj.apartment"
					    :class="{'change': changes.apartment}"
					    :label="$t(`personTable.apartment`)"
				    ></v-text-field >
                    </v-col >
                    <v-col
					cols="12"
					class="p-p-0"
				>
                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('city')"
					    v-model="personObj.city"
					    :class="{'change': changes.city}"
					    :label="$t(`personTable.city`)"
				    ></v-text-field >
                    </v-col >
<!--                    <v-col cols="12">-->
<!--                      <v-divider class="mx-4"></v-divider >-->
<!--                    </v-col >-->
                     <v-col
					 cols="12"
					 class="p-p-0"
				 >

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('bank_name')"
					    v-model="personObj.bank_name"
					    :class="{'change': changes.bank_name}"
					    :label="$t(`personTable.bank_name`)"
				    ></v-text-field >
                    </v-col >
                 <v-col
				  cols="12"
				  class="p-p-0"
			  >
                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('bank_branch')"
					    :class="{'change': changes.bank_branch}"
					    v-model="personObj.bank_branch"

					    :label="$t(`personTable.bank_branch`)"
				    ></v-text-field >
                    </v-col >
                 <v-col
				  cols="12"
				  class="p-p-0"
			  >
                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('bank_account')"
					    :class="{'change': changes.bank_account}"
					    v-model="personObj.bank_account"
					    :label="$t(`personTable.bank_account`)"
				    ></v-text-field >
                    </v-col >
				 <v-col
					 cols="12"
					 class="p-p-0"
				 >

                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('join_date')"
					    v-model="personObj.join_date"
					    :class="{'change': changes.join_date} "
					    :label="$t(`personTable.join_date`)"
				    ></v-text-field >
                    </v-col >
				 <v-col
					 cols="12"
					 class="p-p-0"
				 >
                        <v-text-field
					    class="p-p-0"


					    @change="updatePersonField('name_to_pay_for')"
					    :class="{'change': changes.name_to_pay_for}"
					    v-model="personObj.name_to_pay_for"
					    :error-messages="errors"
					    :label="$t(`personTable.name_to_pay_for`)"
				    ></v-text-field >
                    </v-col >
			 <v-col
				 cols="12"
				 class="p-p-0"
			 >
                        <v-text-field
					    class="p-p-0"
					    @change="updatePersonField('leaving_date')"
					    v-model="personObj.leaving_date"
					    :class="{'change': changes.leaving_date}"
					    :label="$t(`personTable.leaving_date`)"
				    ></v-text-field >
                    </v-col >
<!--                    <v-col cols="12">-->
<!--                      <v-divider class="mx-4"></v-divider >-->
<!--                    </v-col >-->

                    <v-col
					v-if="isAdmin"

				 cols="12"
				 class="p-p-0"
			 >
                        <v-text-field
					    class="p-p-0"
					    @change="updatePersonField('ez_number')"
					    :class="{'change': changes.ez_number}"
					    v-model="personObj.ez_number"
					    :error-messages="errors"
					    :label="$t(`personTable.ez_number`)"
				    ></v-text-field >
                    </v-col >


                  </v-row >
              </v-card-text >

  </div >
</template >

<script >

import {mapGetters, mapState} from "vuex";

export default {
  name: "personDetails",
  components: {},

  props: {
    isShow: Boolean,
    person: Object,
    changes: {
      type: Object,
      default: () => {
        return {}
      }
    }

  },

  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      showDialog: false,
      menu2: false,
      valid: false,
      personObj: this.person
    };
  },

  watch: {
    person(newPerson) {
      if (newPerson.id) {
        this.personObj = newPerson;
      } else {
        this.personObj = {};
      }
    }
  },
  methods: {
    updatePersonField(field) {
      this.$emit("updatePersonSuggestion", field)
    }
  },
  computed: {
    ...mapState(['colelIds']),
    ...mapGetters(["isAdmin"])
  },
  mounted() {
    // this.personObj = this.person
  }
};
</script >

<style scoped>
>>> .change input {

    color: red !important;
}

#colel-pick {
    /*width: 35vw;*/
}

>>> .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    /*padding-right: 0 !important;*/
    margin-right: 0 !important;
}
</style >
