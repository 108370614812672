<template >
  <div class="articles">
      <statisticCard
          :icon="'mdi-book-open-page-variant'"
          :color="'yellow'"
          :statistic="statistic.from_start_count"
          :description="'מאמרים בע״פ'"
      ></statisticCard >
  </div >
</template >

<script >
import statisticCard from '@/components/statisticCard.vue'

export default {
  name: "articles",
  components: {
    statisticCard
  },
  props:{
    statistic: String
  },
};
</script >
