<template >
  <div class="colelMessage">
	  <validation-observer
		  ref="colelMessage"
		  v-slot="{ invalid }"
	  >

            <form @submit.prevent="submit">
                  <v-row >
                    <v-col
					cols="12"
					xs="12"
					sm="3"
				>
				    <validation-provider
					    v-slot="{ errors }"
					    name="message_color"
					    :rules="{
                          required: true,
                    }"
				    >
					   <v-select
							@change="updateInput(invalid)"
							:error-messages="errors"
							:label="$t(`colelMessage.color`)"
							v-model="message.message_color"
							:items="items"
							persistent-hint
							return-object
							single-line
						></v-select >
                      </validation-provider >

                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="6"
				>
				      <validation-provider
						 v-slot="{ errors }"
						 name="title"
						 :rules="{
                          required: true,
                        }"
					 >

 <v-text-field
	 @change="updateInput(invalid)"
	 :error-messages="errors"
	 v-model="message.special_message_title"
	 :label="$t(`colelMessage.special_message_title`)"
 ></v-text-field >
                      </validation-provider >


                    </v-col >
                    <v-col
					cols="12"
					xs="12"
					sm="12"
				>
				    				      <validation-provider
										 v-slot="{ errors }"
										 name="content"
										 :rules="{
                          required: true,
                        }"
									 >

 <v-text-field
	 @change="updateInput(invalid)"
	 :error-messages="errors"
	 v-model="message.special_message_content"
	 :label="$t(`colelMessage.special_message_content`)"
 ></v-text-field >
                      </validation-provider >

                    </v-col >
                  </v-row >
			    </form >
          </validation-observer >
  </div >
</template >

<script >
const defaultMessage = {
  message_color: null,
  special_message_title: null,
  special_message_content: null
}
import {required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import i18n from "@/translate/translate";
import {updateColel} from "../../colelTable";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: i18n.t(`form.required`)
});
export default {
  name: "colelMessage",
  components: {
    ValidationObserver,
    ValidationProvider

  },
  props: {
    isShow: Boolean,
    colelProp: Object
  },
  // mounted() {
  //   this.updateInput()
  //
  //   //
  //   if (this.colelProp) {
  //     //     console.log("&&&&&&&&&",this.colelProp)
  //     this.message = this.colelProp.colel));
  //
  //     //
  //   }
  //   //   // else {
  //   //   //   this.message = defaultMessage
  //   //   // }
  // },
  data() {
    return {
      message: defaultMessage,
      items: [
        "red",
        "blue",
        "yellow",
        "green",
      ]
    };
  },
  created() {
    if (this.colelProp.colel){
      // this.colelProp.colel.message_color = this.items.find(color => color.value
		// === this.colelProp.colel.message_color)
      this.message = {...this.colelProp.colel}
    }
  },
  watch: {
    colelProp(newColel) {
      this.updateInput()

      // this.$refs.colelMessage.reset();

      this.message = {...newColel.colel};
      this.updateInput()
    }
  },
  methods: {
    async updateInput() {
      // setTimeout(async () =>{
      //   const valid = await this.$refs.colelMessage.validate()

      this.$emit("updateInput")
      // } , )


    },
    clodeDialog() {
      this.$emit("closeDialog");
    },
    cancel() {
      this.message = {}
      this.$refs.colelMessage.reset();
    }
  }
};
</script >
