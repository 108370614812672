<template>
  <div class="colelSettings">
    <v-row>
      <v-col
          cols="12"
          xs="12"
          sm="6"
      >
        <v-switch
            v-model="settings.manager_lock"
            inset
            :label="$t(`colelSettings.manager_lock`)"
        ></v-switch>

      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="6"
      >
        <v-switch
            v-model="settings.multiple_update"
            inset
            :label="$t(`colelSettings.multiple_update`)"
        ></v-switch>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="6"
      >
        <v-switch
            v-model="settings.show_calender"
            inset
            :label="$t(`colelSettings.show_calender`)"
        ></v-switch>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="6"
      >
        <v-switch
            v-model="settings.extract_report"
            inset
            :label="$t(`colelSettings.extract_report`)"
        ></v-switch>
      </v-col>
    </v-row>

  </div>
</template>

<script>
const defaultSettings = {
  show_calender: false,
  manager_lock: false,
  multiple_update: false,
  extract_report: false,
  colel_level: 1
}

export default {
  name: "colelSettings",
  props: {
    colelProp: Object
  },

  data() {
    return {
      settings: {...defaultSettings},
    };
  },
  created() {
    if (this.colelProp.colel_details?.settings) {
      this.settings = {...this.colelProp.colel_details.settings};
    }
  },
  watch: {
    colelProp(newColel) {
      this.updateInput()
      if (newColel.colel_details?.settings) {
        this.settings = {...newColel.colel_details.settings};
      }
      this.updateInput()
    }
  },
  methods: {
    async updateInput() {
      this.$emit("updateInput")
    },
    cancel() {
      this.settings = {...defaultSettings}
    }
  }
};
</script>
