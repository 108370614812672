var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colelMessage"},[_c('validation-observer',{ref:"colelMessage",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"message_color","rules":{
                          required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"label":_vm.$t("colelMessage.color"),"items":_vm.items,"persistent-hint":"","return-object":"","single-line":""},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.message.message_color),callback:function ($$v) {_vm.$set(_vm.message, "message_color", $$v)},expression:"message.message_color"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"title","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelMessage.special_message_title")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.message.special_message_title),callback:function ($$v) {_vm.$set(_vm.message, "special_message_title", $$v)},expression:"message.special_message_title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"content","rules":{
                          required: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t("colelMessage.special_message_content")},on:{"change":function($event){return _vm.updateInput(invalid)}},model:{value:(_vm.message.special_message_content),callback:function ($$v) {_vm.$set(_vm.message, "special_message_content", $$v)},expression:"message.special_message_content"}})]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }