/* eslint-disable no-unused-vars */
<template >
  <div class="colelDetails">
                <v-container >
                  <v-row >

                     <v-col
					 cols="12"
					 xs="12"
					 sm="4"
				 >

                        <v-text-field
					    @change="updateColelSuggestion('city')"
:class="{'change': changesObj.city}"
					    v-model="colelObj.city"
					    :label="$t(`colelDialog.city`)"
				    ></v-text-field >
                    </v-col >
                     <v-col
					 cols="12"
					 xs="12"
					 sm="4"
				 >

                        <v-text-field
					    :class="{'change': changesObj.street}"
					    @change="updateColelSuggestion('street')"

					    v-model="colelObj.street"
					    :label="$t(`colelDialog.street`)"
				    ></v-text-field >
                    </v-col >
                     <v-col
					 cols="12"
					 xs="12"
					 sm="3"
				 >

                        <v-text-field
					    :class="{'change': changesObj.apartment}"
					    @change="updateColelSuggestion('apartment')"

					    v-model="colelObj.apartment"
					    :label="$t(`colelDialog.apartment`)"
				    ></v-text-field >
                    </v-col >
                     <v-col
					 cols="3"
					 xs="3"
					 sm="8"
				 >
                        <v-text-field
					    :class="{'change': changesObj.address_description}"
					    @change="updateColelSuggestion('address_description')"

					    v-model="colelObj.address_description"
					    :label="$t(`colelDialog.address_description`)"
				    ></v-text-field >
                    </v-col >


					  <v-col
					   cols="3"
					   xs="3"
					   sm="3"
				   >
  <v-text-field
	  :class="{'change': changesObj.mail_address}"
	  @change="updateColelSuggestion('mail_address')"

	  v-model="colelObj.mail_address"
	  :label="$t(`colelDialog.mail_address`)"
  ></v-text-field >
                    </v-col >

                  </v-row >
                </v-container >
  </div >
</template >

<script >

export default {
  name: "colelDetails",
  components: {
  },
  props: {
    isShow: Boolean,
    colel: Object,
    changesObj: Object
  },
  data() {
    return {
      valid: false,
      colelObj: {},
      persons:null
    };
  },
  created() {
    if (this.colel.colel){
      // this.colelObj = {...this.colel.colel}
      this.colelObj = this.colel.colel
    }
    // this.fillPersonDropDown()
  },
  watch: {
     colel(newProp) {
       if (newProp?.colel?.id) {

        // this.updateInput()
        this.colelObj = {...newProp.colel};
      }

    }
  },
  methods: {
    updateColelSuggestion(field){
      this.$emit("updateColelSuggestion", field);
    }
  }
};
</script >

<style scoped>
>>> .change   input{

   color: red !important;
}


</style>
